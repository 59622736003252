import axios from "../utils/server/axios";

const terminalManagement = {
    // 获取组织树
    getOrganizationTree: (data) =>
        axios("/api/device/management/getOrganizationTree", "GET", data),
    // 列表查询+高级搜索+导入查询
    getAdvancedSearch: (data) =>
        axios("/api/device/management/list", "POST", data),
    // 下载终端管理查询模板
    getDownloadImportTemplate: () =>
        axios(
            "/api/device/management/downloadSearchTemplate",
            "GET",
            {},
            8000,
            "blob"
        ),
    // 下载终端管理导入查询失败原因
    downLoadDetectionErrorMsg: () =>
        axios(
            "/api/device/management/downloadSearchErrorTemplate",
            "GET",
            {},
            8000,
            "blob"
        ),
    // 导出终端列表
    terminalDownloadList: (data) =>
        axios("/api/device/management/exportDevice", "POST", data, 30000, "blob"),
    // 下载导入终端信息模板
    importTemplate: () =>
        axios(
            "/api/device/management/downloadImportTemplate",
            "GET",
            {},
            8000,
            "blob"
        ),
    // 下载导入终端错误信息
    importTemplateError: () =>
        axios(
            "/api/device/management/downloadImportErrorTemplate",
            "GET",
            {},
            8000,
            "blob"
        ),
    // 修改+批量修改
    edit: (data) => axios("/api/device/management/updateDevice", "POST", data),
    // 更新+批量更新
    update: (data) =>
        axios("/api/device/management/updateSimCardInfo", "POST", data),
    // 删除+批量删除
    delate: (data) =>
        axios("/api/device/management/deleteDeviceInfo", "POST", data),
    // 详情操作（包括sim卡信息）
    detailSIM: (data) =>
        axios("/api/device/management/getDeviceDetail", "POST", data),
    // 详情页更新（sim卡号+余额+更新时间）
    detailSpecialUpdate: (data) =>
        axios("/api/device/management/updateDeviceDetailSimCardInfo", "POST", data),
    // 详情页面获取数据（定位信息）
    detailPosition: (data) =>
        axios(
            "/api/device/management/updateDeviceDetailLocationInfo",
            "POST",
            data
        ),
    // 充值详情
    chargeDetail: (data) => axios("/api/recharge/getDeviceInfo", "POST", data),
    // 提交充值
    chargePost: (data) => axios("/api/recharge/submit", "POST", data),
    // 充值页面更新
    chargeUpdate: (data) =>
        axios(
            "/api/device/management/updateDeviceRechargeSimCardInfo",
            "POST",
            data
        ),
    // 订单查询-->用户选择充值成功后，更新充值状态
    rechargeQuery: (data) => axios("/api/recharge/query", "POST", data),

    /**
     * 下发短信相关接口
     */
    // 获取所有服务器信息
    getAllServersData: (data) => axios("/api/group/findAllServers", "POST", data),
    // 下发短信
    sendSmsMessage: (data) => axios("/api/sendMessage/send", "POST", data),
    // 判断终端是否在线
    checkDeviceisOnLine: (data) =>
        axios("/api/sendMessage/isOnLine", "POST", data),
    // 读取终端参数
    readDeviceParam: (data) => axios("/api/sendMessage/read", "POST", data),
    // 终端更新
    getTerminalUpdate: (data) =>
        axios("/api/device/management/updateDeviceInfo", "POST", data),
    // 开关机检测
    getDetection: (data) => axios("/api/sendMessage/sms", "POST", data),
    // 获取终端详情
    getTerminalDetail: (data) =>
        axios(`/api/device/management/${data.id}`, "GET", {}),
    // 转出
    terminalRollout: (data) =>
        axios("/api/device/management/deviceOrg/update", "POST", data),
    // 更新定位
    sendDiagnosisOrder: (data) =>
        axios("/api/applet/device/diagnosis/msg/transmit", "POST", data),
    // 本月和上月流量
    getTraffic: (data) => axios("/api/simCard/flow/month/detail", "POST", data),
    // 下发临时位置追踪
    signalDetect: (data) => axios("/api/nio/gpsTrack", "POST", data),
    // 查询终端参数(下发8106)
    queryTerminalParam: (data) => axios("/api/nio/parameterQuery", "POST", data),
    // 设置终端参数(下发8106)
    setTerminalParam: (data) => axios("/api/nio/parameterSet", "POST", data),
    // 获取远程平台列表
    getRemotePlatformList: (data) => axios("/api/applet/remotePlatform/list", "GET", data),
    // 收藏为常用地址接口
    addServers: (data) => axios("/api/group/addServers", "POST", data),
    // 设置终端参数(下发8106)
    remoteLoginApi: (data) => axios("/api/applet/remotePlatform/connect", "POST", data),
    //获取车牌颜色
    getPlateColor: (data) => axios("/api/applet/remotePlatform/platColor", "GET", data),
    //获取协议类型列表
    getConventionList: (data) => axios("/api/applet/remotePlatform/deviceType/list", "GET", data),
    //获取组织列表
    getOrgList: (data) => axios("/api/applet/remotePlatform/org", "GET", data),
    //获取终端信息
    getTerminalInfo: (data) => axios("/api/applet/remotePlatform/deviceInfo", "GET", data),
    // 远程录入接口
    remoteAdd: (data) => axios("/api/applet/remotePlatform/input/vehicle", "POST", data),
    // 下发8103(终端参数查询)
    terminalParameters: (data) => axios("/api/nio/terminal/parameters", "POST", data),
    // 获取行政区划信息
    getDivisionsList: (data) => axios("/api/common/divisions", "GET", data),
    // 开始转发
    startProxy: (data) => axios("/api/device/proxy/start", "POST", data),
    // 结束转发
    stopProxy: (data) => axios("/api/device/proxy/stop", "POST", data),
    // 向设备发送消息
    sendDevice: (data) => axios("/api/device/proxy/send/device", "POST", data),
    // 向转发平台发送消息
    sendServer: (data) => axios("/api/device/proxy/send/server", "POST", data),
    // 下发TTS
    sendTTS: (data) => axios("/api/sendMessage/tts", "POST", data),
    // 下发点名
    rollCall: (data) => axios("/api/sendMessage/rollCall", "POST", data),
};

export default terminalManagement;
